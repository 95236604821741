import { Controller } from "@hotwired/stimulus"
import ClipboardJS from "clipboard"
import tippy from "tippy.js";

export default class extends Controller {
  static values = {
    id: Number,
    successMessage: String,
    errorMessage: String
  }

  connect() {
    this.clipboard = new ClipboardJS(this.element);
    this.clipboard.on("success", (e) => this.tooltip(this.successMessageValue))
    this.clipboard.on("error",   (e) => this.tooltip(this.errorMessageValue))

    this.tippyInstance = tippy(this.element, {
      trigger: 'manual'
    });

  }

  disconnect() {
    if (this.tippyInstance) {
      this.tippyInstance.destroy();
    }
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  tooltip(message) {
    this.tippyInstance.setContent(message);
    this.tippyInstance.show();

    // Hide the tooltip after 2 seconds
    setTimeout(() => {
      this.tippyInstance.hide();
    }, 2000);
  }


  
}
